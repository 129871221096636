import { EventParamsType, EventsDictionary } from '../types';

export enum CardEvents {
  CardsHomeView = 'cards:Home_view',
  CardsEmployeesWithoutCardsView = 'cards:EmployeesWithoutCards_view',
  CardsEmployeesWithCardsView = 'cards:EmployeesWithCards_view',
  CardsMyIssuesView = 'cards:MyIssues_view',
  CardsInputSearchClick = 'cards:CardsInputSearch_click',
  CardsIssueCardClick = 'cards:IssueCard_click',
  CardsIssueCardSelectTypeView = 'cards:IssueCardSelectType_view',
  CardsIssueCardSelectTypeClick = 'cards:CardsIssueCardSelectTypeClick',
  CardsSingleAddressesPerCompanyChooseEmployeeView = 'cards:CardsSingleAddressesPerCompanyChooseEmployee_view',
  CardsSingleAddressesPerCompanyChooseEmployeeSelectItemClick = 'cards:CardsSingleAddressesPerCompanyChooseEmployeeSelectItem_click',
  CardsSingleAddressesPerCompanyConfirmView = 'cards:CardsSingleAddressesPerCompanyConfirm_view',
  CardsSingleAddressesPerCompanyConfirmEditAddressClick = 'cards:CardsSingleAddressesPerCompanyConfirmEditAddress_click',
  CardsSingleAddressesPerCompanyConfirmSaveAddressClick = 'cards:CardsSingleAddressesPerCompanyConfirmSaveAddress_click',
  CardsSingleAddressesPerCompanyConfirmSendClick = 'cards:CardsSingleAddressesPerCompanyConfirmSend_click',
  CardsSingleAddressesPerCompanyConfirmCancelClick = 'cards:CardsSingleAddressesPerCompanyConfirmCancel_click',
  CardsSingleAddressesPerCompanySuccessView = 'cards:CardsSingleAddressesPerCompanySuccess_view',
  CardsSingleAddressesPerCompanySuccessBackCardsClick = 'cards:CardsSingleAddressesPerCompanySuccessBackCards_click',
  CardsSingleAddressesPerCompanySuccessTrackingClick = 'cards:CardsSingleAddressesPerCompanySuccessTracking_click',
  CardsMultipleAddressesPerCompanyEmptyGroupView = 'cards:CardsMultipleAddressesPerCompanyEmptyGroup_view',
  CardsMultipleAddressesPerCompanySelectGroupView = 'cards:CardsMultipleAddressesPerCompanySelectGroup_view',
  CardsMultipleAddressesPerCompanySelectGroupSendClick = 'cards:CardsMultipleAddressesPerCompanySelectGroupSend_click',
  CardsMultipleAddressesPerCompanyChooseAddressView = 'cards:CardsMultipleAddressesPerCompanyChooseAddress_view',
  CardsMultipleAddressesPerCompanyChooseAddressEditClick = 'cards:CardsMultipleAddressesPerCompanyChooseAddressEdit_click',
  CardsMultipleAddressesPerCompanyChooseAddressSaveClick = 'cards:CardsMultipleAddressesPerCompanyChooseAddressSave_click',
  CardsMultipleAddressesPerCompanyChooseAddressCancelClick = 'cards:CardsMultipleAddressesPerCompanyChooseAddressCancel_click',
  CardsMultipleAddressesPerCompanyChooseAddressSendClick = 'cards:CardsMultipleAddressesPerCompanyChooseAddressSend_click',
  CardsMultipleAddressesPerCompanyConfirmationView = 'cards:CardsMultipleAddressesPerCompanyConfirmation_view',
  CardsMultipleAddressesPerCompanyConfirmationSendClick = 'cards:CardsMultipleAddressesPerCompanyConfirmationSend_click',
  CardsMultipleAddressesPerCompanyConfirmationCancelClick = 'cards:CardsMultipleAddressesPerCompanyConfirmationCancel_click',
  CardsMultipleAddressesPerCompanySuccessView = 'cards:CardsMultipleAddressesPerCompanySuccessView',
  CardsMultipleAddressesPerCompanySuccessBackCardsClick = 'cards:CardsMultipleAddressesPerCompanySuccessBackCards_click',
  CardsMultipleAddressesPerCompanySuccessTrackingCardsClick = 'cards:CardsMultipleAddressesPerCompanySuccessTrackingCards_click',
  CardsTrackingDetailView = 'cards:CardsTrackingDetailView',
  CardsTrackingDetailLinkClick = 'cards:CardsTrackingDetailLink_click',
  CardsEmployeeWithCardsDetailActionSecondWayClick = 'cards:CardsEmployeeWithCardsDetailActionSecondWay_click',
  CardsSecondWayDetailCancelClick = 'cards:CardsSecondWayDetailCancel_click',
  CardsDeliveryAddressReissueCompanyClick = 'cards:Delivery_Company_click',
  CardsDeliveryAddressReissueEmployeeClick = 'cards:Delivery_Collaborator_click',
  CardsConfirmReissueConfirmButtonClick = 'cards:ConfirmSolicitation_click',
  CardsMultipleAddressesPerCompanyEmptyGroupGoToSelectTypeClick = 'cards:CardsMultipleAddressesPerCompanyEmptyGroupGoToSelectTypeClick',
  CardsLimitedQuotaNotificationOpenZendeskClick = 'cards:LimitedQuotaNotificationOpenZendeskClick',
  CardsLimitedQuotaNoNameNotificationOpenZendeskClick = 'cards:LimitedQuotaNoNameNotificationOpenZendeskClick',
  CardsIssueSelectCardTypeView = 'cards:CardsIssueSelectCardTypeView',
  CardsIssueSelectCardTypeSendClick = 'cards:CardsIssueSelectCardTypeSendClick',
  CardsIssueSelectCardTypeCancelClick = 'cards:CardsIssueSelectCardTypeCancelClick',
  CardsNoNameQuantityView = 'cards:CardsNoNameQuantityView',
  CardsNoNameQuantitySendClick = 'cards:CardsNoNameQuantitySendClick',
  CardsNoNameQuantityCancelClick = 'cards:CardsNoNameQuantityCancelClick',
  CardsNoNameShippingAddressView = 'cards:CardsNoNameShippingAddressView',
  CardsNoNameShippingAddressCancelClick = 'cards:CardsNoNameShippingAddressCancelClick',
  CardsNoNameShippingAddressSendClick = 'cards:CardsNoNameShippingAddressSendClick',
  CardsNoNameShippingAddressNotConfirmClick = 'cards:CardsNoNameShippingAddressNotConfirmClick',
  CardsNoNameShippingAddressConfirmClick = 'cards:CardsNoNameShippingAddressConfirmClick',
  CardsNoNameSuccessView = 'cards:CardsNoNameSuccessView',
  CardsNoNameSuccessBackCardsClick = 'cards:CardsNoNameSuccessBackCardsClick',
  CardsCompletedRequestListItemClick = 'cards:CardsCompletedRequestListItemClick',
  CardsIssueSelectCardTypeAddressClick = 'card:CardsIssueSelectCardTypeAddressClick',
  CardsAddressCloseClick = 'cards:CardsAddressCloseClick',
  CardsAddressCancelClick = 'cards:CardsAddressCancelClick',
  CardsAddressOkClick = 'cards:CardsAddressOkClick',
  AntiFraudAntiFraudView = 'AntiFraud:AntiFraudView',
  AntiFraudAntiFraudBackClick = 'AntiFraud:AntiFraudBackClick',
  AntiFraudAntiFraudBackHomeClick = 'AntiFraud:AntiFraudBackHomeClick',
  AntiFraudAntiFraudChatClick = 'AntiFraud:AntiFraudChatClick',
  AntiFraudAntiFraudBannerChatClick = 'AntiFraud:AntiFraudBannerChatClick',
  AntiFraudAntiFraudBannerColabsClick = 'AntiFraud:AntiFraudBannerColabsClick',
}

export type CardEventsType = typeof CardEvents;

export type CardEventType = keyof CardEventsType;

export type CardEventTypeCollection = CardEventType[];

const CardEventKeys = Object.keys(CardEvents) as CardEventTypeCollection;

export const card: EventsDictionary = CardEventKeys.reduce((collection, eventKey) => {
  const eventName = CardEvents[eventKey];

  return {
    ...collection,
    [eventName]: <CardEventParams>(params: EventParamsType<CardEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
